import React, { useState } from 'react'
import ExpandablePoem from '../ExpandablePoem.jsx'
import ExpandableStanza from '../ExpandableStanza.jsx'
import {
  OrgInputPlainLabel,
  OrgDollarsInput,
  OrgSelectInput,
  OrgCreatableSelectInput,
} from '../inputs/OrgInputs.jsx'
import './PoemAssistanceOffered.scss'
import { ControlledOfferingsTree } from '../../../shared/OfferingsTree.jsx'
import OfferingCheckbox from '../../../shared/OfferingCheckbox.jsx'
import usStates from '../../../utilities/us_states.ts'
import toOption from '../../../utilities/to_option.js'

// Offerings listed separately due to additional inputs beyond the checkbox
const SPECIAL_OFFERINGS = [
  'financial_assistance_abortion',
  'judicial_bypass_support',
  'language_services',
]

const PoemAssistanceOffered = ({
  currentNavigatorSupportOrg,
  expanded,
  offeringsTreeData,
  prefix,
  selectOptions,
  toggleExpanded,
  updateNavigatorSupportOrg,
  fromServerErrors = {},
}) => {
  const [showAssistanceOther, setShowAssistanceOther] = useState(
    currentNavigatorSupportOrg.assistance_other_names.length > 0
  )

  const toggleAssistanceOther = (e) => {
    if (!e.target.checked)
      updateNavigatorSupportOrg({ assistance_other_names: [] })
    setShowAssistanceOther(e.target.checked)
  }

  const offeringsTreeDataExceptSpecialOfferings = offeringsTreeData.filter(
    ({ key }) => !SPECIAL_OFFERINGS.includes(key)
  )

  const languageServiceOptions = selectOptions.language_services.map(
    ({ key, name_en }) => ({
      value: key,
      label: name_en,
    })
  )

  const selectedLanguageServiceOfferings = selectOptions.language_services
    .filter(
      (offering) => currentNavigatorSupportOrg.known_offerings[offering.key]
    )
    .map((offering) => offering.key)

  const updateNavigatorSupportOrgLanguageServices = (selected) => {
    const languageOfferingsObject = selectOptions.language_services.reduce(
      (obj, off) => ({ ...obj, [off.key]: selected.includes(off.key) }),
      {}
    )
    updateNavigatorSupportOrg({ known_offerings: languageOfferingsObject })
  }

  const usStateOptions = Object.entries(usStates).map((ele) =>
    toOption(ele.reverse())
  )

  return (
    <ExpandablePoem
      header="Assistance Offered"
      subheader="Types of assistance offered, Language support offered, Procedure funding amount"
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      poemClass="PoemAssistanceOffered"
    >
      <ExpandableStanza sidebar="If “Other” assistance types are listed, text will be translated into Spanish before it’s publicly displayed on the Abortion Finder platform.">
        <OrgInputPlainLabel
          label="Assistance Offered"
          isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          extraStyle="large-print"
        />
        <div className="subsection financial-assistance">
          <OfferingCheckbox
            name={`${prefix}[partial_offerings][financial_assistance_abortion]`}
            label="Financial assistance for abortion"
            onChange={(e) =>
              updateNavigatorSupportOrg({
                known_offerings: {
                  financial_assistance_abortion: e.target.checked,
                },
              })
            }
            checked={
              !!currentNavigatorSupportOrg.known_offerings
                .financial_assistance_abortion
            }
          />
        </div>
        {currentNavigatorSupportOrg.known_offerings
          .financial_assistance_abortion && (
          <div className="funding-inputs">
            <OrgDollarsInput
              name={`${prefix}[min_funding]`}
              label="Minimum Funding Amount"
              onChange={(amt) =>
                updateNavigatorSupportOrg({ min_funding: amt })
              }
              value={currentNavigatorSupportOrg.min_funding}
              extraStyle="not-serif"
              errors={fromServerErrors.min_funding}
            />
            <OrgDollarsInput
              name={`${prefix}[avg_funding]`}
              label="Average Funding Amount"
              onChange={(amt) =>
                updateNavigatorSupportOrg({ avg_funding: amt })
              }
              value={currentNavigatorSupportOrg.avg_funding}
              extraStyle="not-serif"
              errors={fromServerErrors.avg_funding}
            />
            <OrgDollarsInput
              name={`${prefix}[max_funding]`}
              label="Maximum Funding Amount"
              onChange={(amt) =>
                updateNavigatorSupportOrg({ max_funding: amt })
              }
              value={currentNavigatorSupportOrg.max_funding}
              extraStyle="not-serif"
              errors={fromServerErrors.max_funding}
            />
          </div>
        )}

        <div className="subsection judicial_bypass_support">
          <OfferingCheckbox
            name={`${prefix}[partial_offerings][judicial_bypass_support]`}
            label="Judicial bypass support"
            onChange={(e) =>
              updateNavigatorSupportOrg({
                known_offerings: {
                  judicial_bypass_support: e.target.checked,
                },
              })
            }
            checked={
              !!currentNavigatorSupportOrg.known_offerings
                .judicial_bypass_support
            }
          />
          {currentNavigatorSupportOrg.known_offerings
            .judicial_bypass_support && (
            <>
              <OrgInputPlainLabel
                label="Judicial bypass support state(s)"
                padding={true}
                extraStyle="not-serif"
              />
              <OrgSelectInput
                isMulti={true}
                label="" // no label
                name={`${prefix}[judicial_bypass_support_states][]`}
                onChange={(states) =>
                  updateNavigatorSupportOrg({
                    judicial_bypass_support_states: states,
                  })
                }
                options={usStateOptions}
                value={
                  currentNavigatorSupportOrg.judicial_bypass_support_states
                }
              />
            </>
          )}
        </div>

        <div className="divider" />

        <ControlledOfferingsTree
          treeData={offeringsTreeDataExceptSpecialOfferings}
          knownOfferings={currentNavigatorSupportOrg.known_offerings}
          applyKnownOfferings={(newOfferings) =>
            updateNavigatorSupportOrg({ known_offerings: newOfferings })
          }
          inputNamePrefix={`${prefix}[partial_offerings]`}
          disabled={false}
          yesNoUnknown={false}
        />

        <div className="divider" />

        <div className="subsection other">
          <OfferingCheckbox
            label="Other"
            onChange={toggleAssistanceOther}
            checked={showAssistanceOther}
          />
          {showAssistanceOther ? (
            <>
              <OrgInputPlainLabel
                label={'List "Other" assistance types'}
                padding={true}
                extraStyle="not-serif"
              />
              <OrgCreatableSelectInput
                value={currentNavigatorSupportOrg.assistance_other_names}
                label="" // no label
                name={`${prefix}[assistance_other_names][]`}
                onChange={(v) =>
                  updateNavigatorSupportOrg({ assistance_other_names: v })
                }
                isMulti={true}
                isClearable={true}
                options={selectOptions.assistance_other_names}
                errors={fromServerErrors.assistance_other_names}
              />
            </>
          ) : (
            <input
              type="hidden"
              name={`${prefix}[assistance_other_names][]`}
              value=""
            />
          )}
        </div>

        <div className="divider" />

        <div className="subsection languages">
          <input
            type="hidden"
            name={`${prefix}[partial_offerings][language_services]`}
            value={selectedLanguageServiceOfferings.length > 0}
          />
          {selectOptions.language_services.map((off) => (
            <input
              type="hidden"
              key={off.key}
              name={`${prefix}[partial_offerings][${off.key}]`}
              value={currentNavigatorSupportOrg.known_offerings[off.key] || ''}
            />
          ))}
          <OrgSelectInput
            value={selectedLanguageServiceOfferings}
            label="Language Support Offered"
            onChange={updateNavigatorSupportOrgLanguageServices}
            isMulti={true}
            options={languageServiceOptions}
            isInfoPublic={!currentNavigatorSupportOrg.private_support_org}
          />
        </div>
      </ExpandableStanza>
    </ExpandablePoem>
  )
}

PoemAssistanceOffered.openIfErrors = [
  'assistance_other_names',
  'avg_funding',
  'judicial_bypass_support_states',
  'max_funding',
  'min_funding',
  'partial_offerings',
]

export default PoemAssistanceOffered
